<template>
  <div class="home">
    <div class="header">
      <div>
        <img src="../static/我的/touxiang3.png" alt="" width="101" height="101">
        <p class="name">{{list[0].user_name}}</p>
      </div>
    </div>

    <div class="opts">
      <div class="o1" @click=routeTo(1)>
        <img src="../static/我的/wanshanziliao.png" alt="" width="30" height="30">
        <p style="margin-left:15px">完善资料</p>
      </div>
      <van-icon name="arrow" />
    </div>
  <!--  <div class="opts">
      <div class="o1">
        <img src="../static/我的/xiugaimima.png" alt="" width="30" height="30">
        <p style="margin-left:15px">修改密码</p>
      </div>
      <van-icon name="arrow" />
    </div> -->
    <div class="opts">
      <div class="o1" @click=routeTo(2)>
        <img src="../static/我的/tuichu(1).png" alt="" width="30" height="30">
        <p style="margin-left:15px">退出登录</p>
      </div>
      <van-icon name="arrow" />
    </div>
  </div>
</template>


<script>
	import moment from 'moment';

export default {
  data() {
    return {
      active: 0,
	  list:{},
	  
    };
  },created () {
    this.getHistoryData()
  },
methods:{
	routeTo(name)
	{
 		console.log(name);
		if(name == '1')
	  {
		  
		  this.$router.push({
		    path: `/wanshanziliao?id=`+this.list[0].role_id,
		  })
	  }
	  if(name == '2')
	  {
		  this.$router.push({
		    path: `/`,
		  })
	  }
		
	},
	dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
 
    onClickLeft() {
    this.$router.go(-1);//返回上一层
    },
 
 getHistoryData(){
 	let param = new URLSearchParams();
 	
 	
 	param.append("by", 'customer');
 	 param.append("token", this.$store.getters. getDemoValue);
 	
 	this.axios.post('/vue.php?m=index&a=dynamic',param).then(res => {
 		if (res.data.status === -1) {

 			setTimeout(()=>{
 				this.$router.push({
 				  path:"/"
 				})
 			},1000)
 		}
 		if (res.data.status === 1) {
 			
			this.list = res.data.list;
 			console.log(this.list);
 		
 		}
 		console.log(res);
 	}).catch(function (error){
 		console.log(error);
 	});
 }
 
},

};
</script>
<style lang="scss" scoped>
.home {
  background: #f2f2f2;
  height: 100vh;
}
.header {
  background: url("../static/我的/di.png") no-repeat;
  height: 245px;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .name {
  align-items: center;
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    color: #ffffff;
  }
}
.opts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 351px;
  height: 52px;
  background: #ffffff;
  border-radius: 5px;
  margin: 15px auto;
  .o1 {
    width: 75%;
    display: flex;
    align-items: center;
  }
}
</style>